// file purpose: hold very high level sugar type code which is reusable nearly anywhere
// other than mostly universal code (like syntax sugar libs, and fable) nothing should be imported into this file

import * as Option from './adapters/fable/Option'

export const addClasses = (classes: string[], className?: string) => {
    if (!!className) {
        return [className, ...classes].join(' ');
    }
    let warnings = classes.filter(v => v.includes("."))
    if (warnings.length > 0) {
        console.warn('dot in className(s)', warnings)
    }
    return classes.join(' ');
}

export const addComponentClasses = <T>(props: T & { className?: string }, classes: string[]): T => {
    if (Object.keys(props).includes("className")) {
        let result = Object.assign({}, props, addClasses(classes, props.className));
        console.log('adding comp classes', props.className, classes, result);
        return result;
    } else {
        let result = Object.assign({}, props, { className: addClasses(classes) });
        return result;
    }
};


export function equalsCI(a?: string, b?: string) {
    return typeof a === 'string' && typeof b === 'string'
        ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
        : a === b;
}
export function equalsAnyCI(a: string, items: string[]) {
    return items.find(x => equalsCI(x, a)) !== undefined;
}

// export default { addClasses }
// export interface Reusable {
//     addClasses: addClasses; // (props:{}, classes: string[]) : {};
// }
// type ResultBase = { state: null }
// type ResultOk<T> = { state: "ok"; value: T }
type ResultError<TError> = { state: "error"; error: TError }

// https://github.com/fable-compiler/Fable/blob/main/src/fable-library/Types.ts
// fable uses this internally:
// export type Result<T> = { tag: "ok"; value: T } | { tag: "error"; error: string };
export type Result<T, TError> = { state: "ok"; value: T } | ResultError<TError>

export function resultOk<T, TError>(value: T): Result<T, TError> { return { state: "ok", value }; }
export function resultError<T, TError>(error: TError): Result<T, TError> { return { state: "error", error }; }
// export namespace Option {

//     // reworking to fit sample code from https://stackoverflow.com/questions/71167632/how-is-the-maybe-monad-useful-in-typescript
//     interface None { type: "none" }
//     interface Some<T> { type: "some"; value: T }

//     export type Option<T> = Some<T> | None

//     export const None = (): None => ({ type: "none" });
//     export const Some = <T>(value: T): Some<T> => ({
//         type: "some",
//         value
//     })
// }


// ({
//     type: None
// })
// export class Option<T>{
//     readonly item: OptionNone | OptionSome<T>;


//     constructor();
//     constructor(value?: T) {
//         if (value !== undefined)
//             this.item = { state: "some", value: value }
//         else
//             this.item = { state: "none" }
//     }

//     get value() {
//         if(this.item.state === "some")
//             return this.item.value;
//         return undefined;
//     }

//     get isSome() {
//         return this.item.state === "some";
//     }

//     map<TDest>(f:(item:T) => TDest) {
//         if(this.isSome)
//             return new Option(f(this.item.value))
//     }
// }
// export module Option {
//     export function map()
// }

export const tryF = <TResult>(f: (() => TResult)): Result<TResult, unknown> => {
    try {
        let value = f();
        return { state: "ok", value };
    } catch (ex) {
        return { state: "error", error: ex };
    }
}

export const tryGetOk = <TResult>(r: Result<TResult, unknown>): (TResult | undefined) => {
    switch (r.state) {
        case "ok":
            return r.value;
        default:
            return undefined;
    }
}

// TODO: make this humanize pascal and camel case
export const humanize = (value: string): string => {
    if (!value) return value;
    return value.replace(/_/g, ' ',);
}

export const addQueryParam = (url: string, key: string, value: string) => {
    var eValue = encodeURIComponent(value);
    if (url.includes('?'))
        return `${url}&${key}=${eValue}`;
    return `${url}?${key}=${eValue}`;
};

export const addQueryParamIfValue = (url: string, key: string, value: string | undefined) => {
    if (!!value) return addQueryParam(url, key, value);
    return url;
}

export const addQueryParams = (url: string, values: { key: string, value: string }[]) => {
    let query = values.map(x => `${x.key}=${encodeURIComponent(x.value)}`).join()
    if (url.includes('?'))
        return `${url}&${query}`;
    return `${url}?${query}`;
}

// export function* choose<TSrc,TDest>(items:Iterable<TSrc>, f: (item:TSrc) => Option<TDest> ):Iterable<TDest>{
export function* choose<TSrc, TDest>(items: TSrc[], f: (item: TSrc) => Option.Option<TDest>) {
    for (const v of items) {
        let next = Option.unwrap(f(v));
        if (!!next)
            yield next;
    }
}

// based on https://mui.com/x/react-date-pickers/lifecycle/#server-interaction
export function debounce<T>(func: (arg: T) => void, wait = 500) {
    let timeout: NodeJS.Timeout;
    function debounced(arg: T) {
        const later = () => {
            func(arg);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    }

    debounced.clear = () => {
        clearTimeout(timeout);
    };

    return debounced;
}
// https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
function onlyUnique<T>(value: T, index: number, array: T[]) {
    return array.indexOf(value) === index;
}

export const distinct = <T>(items: T[]) => items.filter(onlyUnique);

export namespace System {
    export type Action = () => void;
    export type Action1<T1> = (x1: T1) => void;

    export type Func<T> = () => T;
    export type Func1<T1, T> = (x1: T1) => T
    export type Func2<T1, T2, T> = (x1: T1, x2: T2) => T

    export type Func3<T1, T2, T> = (x1: T1) => (x2: T2) => T

    export abstract class String {
        public static isNullOrWhitespace = (input: string | null | undefined) => !input || !input.trim();

        // handles null, undefined, NaN, and empty String
        public static isNullOrEmpty = (input: string) => !input || input === '';

        public static afterOrSelf = (delimiter: string, value: string): string => {
            if (String.isNullOrEmpty(delimiter)) throw "delimiter was empty"
            if (String.isNullOrEmpty(value)) return value;
            var i = value.indexOf(delimiter);
            if (i < 0) return value;
            return value.slice(i + delimiter.length);
        }

        public static contains = (delimiter: string, value: string): boolean => {
            if (String.isNullOrEmpty(delimiter)) throw ("Delimiter must be a value");
            if (String.isNullOrEmpty(value)) return false;
            return value.includes(delimiter);
        }
        public static containsCI = (delimiter: string, value: string): boolean => {
            if (String.isNullOrEmpty(delimiter)) throw ("Delimiter must be a value");
            if (String.isNullOrEmpty(value)) return false;
            return value.toLowerCase().includes(delimiter.toLowerCase());
        }
    }
}
// declare module String {
//     export function isNullOrWhitespace = (input:string | null | undefined) => !input || !input.trim();
// }
// export function afterOrSelf(delimiter:string, value: string): string {
//     return !input || !input.trim();
// }